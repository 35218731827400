import React from 'react';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Contact from 'components/Contact';
import Main from 'layouts/Main';
import Container from 'components/Container';
import {
  Gallery,
  Hero,
  Story,
  Team,
  WhoWeAre,
  Application,
} from './components';
import { Partners } from 'views/DesignCompany/components';

const About = () => {
  return (
    <Main colorInvert={true}>
      <Hero />
      <Container>
        <Story />
      </Container>
      <Container paddingTop={'0 !important'}>
        <WhoWeAre />
      </Container>
      <Container maxWidth={800} paddingY={'0 !important'}>
      Welcome to our one-stop destination for all your electrical and electronics needs! Explore a wide range of high-quality electrical appliances, reliable wires and cables, energy-efficient fans, and a diverse selection of electronics goods. Discover the perfect solutions to power up your life with safety and style. Whether you're upgrading your home or outfitting your workspace, we've got you covered. Browse our extensive catalog, expertly curated to provide you with the latest innovations and trusted brands. Experience the convenience of shopping online for all things electrical – because excellence in electronics begins here!
        <Divider />
      </Container>
      <Container>
        <Team />
      </Container>
      <Box bgcolor={'alternate.main'}>
        <Container>
          <Partners />
        </Container>
      </Box>
      <Container>
        <Contact />
      </Container>
      <Container>
        <Gallery />
      </Container>
      <Container maxWidth={800} paddingTop={'0 !important'}>
        <Application />
      </Container>
    </Main>
  );
};

export default About;
